.page-header-styles() {

  	h1.page-header {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
	}

	.page-header__wrapper {
		margin-bottom: 0;
	}

	h2.subtitulo {
		margin-bottom: 5px;
		padding: 0;
		font: 400 34px/34px @thinScout;
		@media (min-width: 1281px) {
			font: 400 44px/44px @thinScout;
		}
	}
	
  div#cuerpo-ficha,
  .cuerpo-ficha {
		border-top: 4px solid @grayDark;
  }
    
}

// Titulo de pagina

.page-header {
	margin: 0;
	padding: 0 0 5px 0;
	line-height: 100%;
	border-bottom: 4px solid #333333;
}

.page-header__wrapper {
	margin-bottom: 30px;
}

.buscador-abajo {
	.page-header {
		margin:0;
	}
}