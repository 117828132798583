/*doc
---
title: Avisos en la Home
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

#home-deepbanner {
	+ .alert {
		margin-top: -15px;
		@media (max-width: 767px) {
			margin-top: 30px;
		}
	}
}