/*doc
---
title: Centrar
category: Mixins
---

*/

/**
 * Para centrar correctamente el elemento es necesario añadirle a su padre 
 * la regla 'position: relative;'.
 */

.center {
	width: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	.translate (-50%, -50%);
}