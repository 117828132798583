.splash-button {
	font-family: @splash-button-family;
	font-size: @splash-button-font-size;
  font-weight: normal;
  line-height: 0;
	background-color: @beige;
	transition: .2s;
  text-shadow: none;
  border: none;
  &:hover,
	&:focus {
		background-color: darken(@beige, 10%);
  }
  &:focus {
		outline: none;
	}
}
