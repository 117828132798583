/*doc
---
title: Sprites
category: Mixins
---

Para trabajar comodamente con sprites hay definidos una serie de mixins creados a partir de los ejemplos de [este post](http://www.smashingmagazine.com/2012/04/11/css-sprites-revisited/).

Los sprites de imágenes los guardamos en la carpeta `images/sprites/` y los nombramos de la siguiente forma: `sprite1` , `sprite2` , `sprite3` . Cada sprite consta de una cuadrícula donde se colocan las imágenes y cuyas dimensiones de celda pueden variar. El nombre del sprite, su ruta, y las dimensiones de su cuadrícula las vamos definiendo en el archivo `less/mixins/sprites.less`: 

```
.sprite(@image, @x, @y)  when (@image = sprite1) {
	@spriteX: 32px;
	@spriteY: 32px;
   	.spriteHelper (@x, @y, @spriteX, @spriteY);
   	background-image: url("../images/sprites/@{image}.png");
}
```

Tenemos tres mixins para hacer aplicar los sprites de tres formas diferentes.

##spriteReplace##

```
.spriteReplace (@image, @x, @y, @width, @height);
```

Sirve para reemplazar un texto por una imagen, por ejemplo:

```
.sharing .twitter a {
   .spriteReplace(sprite1, 0, 0, 32px, 32px); display:block;
}
```

##spriteInline##

```
.spriteInline(@image, @x, @y, @width, @height);
```

Sirve para añadir una imagen en línea a un lado de un texto de una sola línea, por ejemplo:

```
.affiliates .amazon a:before {
   .spriteInline(sprite1, 4, 1, 22px, 16px);
}
```

##spritePadded##

```
.spritePadded(@image, @x, @y, @width, @height);
```
Sirve para añadir una imagen a un lado de un texto de más de una línea, por ejemplo:

```
.translated p {
   padding-left: 22px;
   position: relative;
}
.translated p:before {
   .spritePadded(sprite1, 5, 5, 16px, 14px);
   left: 0;
   top: 0;
}
```

*/

.spriteHelper(@x, @y, @spriteX, @spriteY) {
   background-position: -(@x * @spriteX) -(@y * @spriteY);
   background-repeat: no-repeat;
}

// Definimos los nombres de sprites, sus rutas y sus tamaños de cuadrícula

.sprite(@image, @x, @y)  when (@image = sprite1) {
	@spriteX: 32px;
	@spriteY: 32px;
   	.spriteHelper (@x, @y, @spriteX, @spriteY);
   	background-image: url("../images/sprites/@{image}.png?t=20062019");
}

.sprite(@image, @x, @y)  when (@image = sprite2) {
   @spriteX: 50px;
   @spriteY: 50px;
      .spriteHelper (@x, @y, @spriteX, @spriteY);
      background-image: url("../images/sprites/@{image}.svg");
}

.sprite-svg-ie8 (@image) { // Mixin para sustituir el svg por un png para ie8
   background-image: url("../images/sprites/@{image}.png");
}

// Sprite: Reemplazar texto

.hideText {
   text-indent: -999em;
   letter-spacing: -999em;
   overflow: hidden;
}

.spriteReplace (@image, @x, @y, @width, @height) {
   .sprite(@image, @x, @y);
   .hideText;
   width: @width;
   height: @height;
}

// Sprite: Inline images

.spriteInline (@image, @x, @y, @width, @height) {
   .sprite(@image, @x, @y);
   display: inline-block;
   content: "";
   width: @width;
   height: @height;
}

// Sprite: Padded images

.spritePadded (@image, @x, @y, @width, @height) {
   .sprite(@image, @x, @y);
   position: absolute;
   content: "";
   width: @width;
   height: @height;
}