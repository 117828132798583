// Mixins.less
// Snippets of reusable CSS to develop faster and keep code readable
// -----------------------------------------------------------------


// UTILITY MIXINS
// --------------------------------------------------

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

// Webkit-style focus
// ------------------
.tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
// ----------------------------------
.center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// IE7 inline-block
// ----------------
.ie7-inline-block() {
  *display: inline; /* IE7 inline-block hack */
  *zoom: 1;
}

// IE7 likes to collapse whitespace on either side of the inline-block elements.
// Ems because we're attempting to match the width of a space character. Left
// version is for form buttons, which typically come after other elements, and
// right version is for icons, which come before. Applying both is ok, but it will
// mean that space between those elements will be .6em (~2 space characters) in IE7,
// instead of the 1 space in other browsers.
.ie7-restore-left-whitespace() {
  *margin-left: .3em;

  &:first-child {
    *margin-left: 0;
  }
}

.ie7-restore-right-whitespace() {
  *margin-right: .3em;

  &:last-child {
    *margin-left: 0;
  }
}

// Sizing shortcuts
// -------------------------
.size(@height, @width) {
  width: @width;
  height: @height;
}
.square(@size) {
  .size(@size, @size);
}

// Placeholder text
// -------------------------
.placeholder(@color: @placeholderText) {
  :-moz-placeholder {
    color: @color;
  }
  ::-webkit-input-placeholder {
    color: @color;
  }
}

// Text overflow
// -------------------------
// Requires inline-block or block for proper styling
.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
// -------------------------
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}


// FONTS
// --------------------------------------------------

#font {
  #family {
    .serif() {
      font-family: @serifFontFamily;
    }
    .sans-serif() {
      font-family: @sansFontFamily;
    }
    .monospace() {
      font-family: @monoFontFamily;
    }
  }
  .shorthand(@size: @baseFontSize, @weight: normal, @lineHeight: @baseLineHeight) {
    font-size: @size;
    font-weight: @weight;
    line-height: @lineHeight;
  }
  .serif(@size: @baseFontSize, @weight: normal, @lineHeight: @baseLineHeight) {
    #font > #family > .serif;
    #font > .shorthand(@size, @weight, @lineHeight);
  }
  .sans-serif(@size: @baseFontSize, @weight: normal, @lineHeight: @baseLineHeight) {
    #font > #family > .sans-serif;
    #font > .shorthand(@size, @weight, @lineHeight);
  }
  .monospace(@size: @baseFontSize, @weight: normal, @lineHeight: @baseLineHeight) {
    #font > #family > .monospace;
    #font > .shorthand(@size, @weight, @lineHeight);
  }
}


// FORMS
// --------------------------------------------------

// Block level inputs
.input-block-level {
  display: block;
  width: 100%;
  min-height: 28px;        // Make inputs at least the height of their button counterpart
  .box-sizing(border-box); // Makes inputs behave like true block-level elements
}


// Mixin for form field states
.formFieldState(@textColor: #555, @borderColor: #ccc, @backgroundColor: #f5f5f5) {
  // Set the text color
  > label,
  .help-block,
  .help-inline {
    color: @textColor;
  }
  // Style inputs accordingly
  input,
  select,
  textarea {
    color: @textColor;
    border-color: @borderColor;
    &:focus {
      border-color: darken(@borderColor, 10%);
      .box-shadow(0 0 6px lighten(@borderColor, 20%));
    }
  }
  // Give a small background color for input-prepend/-append
  .input-prepend .add-on,
  .input-append .add-on {
    color: @textColor;
    background-color: @backgroundColor;
    border-color: @textColor;
  }
}



// CSS3 PROPERTIES
// --------------------------------------------------

// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
      -ms-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}

// Transformations
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
     -moz-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees);
       -o-transform: rotate(@degrees);
          transform: rotate(@degrees);
}
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
     -moz-transform: scale(@ratio);
      -ms-transform: scale(@ratio);
       -o-transform: scale(@ratio);
          transform: scale(@ratio);
}
.translate(@x, @y) {
  -webkit-transform: translate(@x, @y);
     -moz-transform: translate(@x, @y);
      -ms-transform: translate(@x, @y);
       -o-transform: translate(@x, @y);
          transform: translate(@x, @y);
}
.skew(@x, @y) {
  -webkit-transform: skew(@x, @y);
     -moz-transform: skew(@x, @y);
      -ms-transform: skew(@x, @y);
       -o-transform: skew(@x, @y);
          transform: skew(@x, @y);
}
.translate3d(@x, @y, @z) {
  -webkit-transform: translate(@x, @y, @z);
     -moz-transform: translate(@x, @y, @z);
      -ms-transform: translate(@x, @y, @z);
       -o-transform: translate(@x, @y, @z);
          transform: translate(@x, @y, @z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
	   -moz-backface-visibility: @visibility;
	    -ms-backface-visibility: @visibility;
	        backface-visibility: @visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  -webkit-background-clip: @clip;
     -moz-background-clip: @clip;
          background-clip: @clip;
}

// Background sizing
.background-size(@size){
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}


// Box sizing
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
     -moz-box-sizing: @boxmodel;
      -ms-box-sizing: @boxmodel;
          box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
  -webkit-user-select: @select;
     -moz-user-select: @select;
      -ms-user-select: @select;
       -o-user-select: @select;
          user-select: @select;
}

// Resize anything
.resizable(@direction) {
  resize: @direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@columnCount, @columnGap: @gridGutterWidth) {
  -webkit-column-count: @columnCount;
     -moz-column-count: @columnCount;
          column-count: @columnCount;
  -webkit-column-gap: @columnGap;
     -moz-column-gap: @columnGap;
          column-gap: @columnGap;
}

// Opacity
.opacity(@opacity) {
  opacity: @opacity / 100;
  filter: ~"alpha(opacity=@{opacity})";
}



// BACKGROUNDS
// --------------------------------------------------

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
#translucent {
  .background(@color: @white, @alpha: 1) {
    background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
  }
  .border(@color: @white, @alpha: 1) {
    border-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
    .background-clip(padding-box);
  }
}

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor, @secondaryColor) {
  #gradient > .vertical(@primaryColor, @secondaryColor);
  border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
#gradient {
  .horizontal(@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(left, @startColor, @endColor); // Le standard
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",@startColor,@endColor)); // IE9 and down
  }
  .vertical(@startColor: #555, @endColor: #333) {
    background-color: mix(@startColor, @endColor, 60%);
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(top, @startColor, @endColor); // The standard
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down
  }
  .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
    background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
  }
  .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
    background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down, gets no color-stop at all for proper fallback
  }
  .radial(@innerColor: #555, @outerColor: #333)  {
    background-color: @outerColor;
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
    background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -ms-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -o-radial-gradient(circle, @innerColor, @outerColor);
    background-repeat: no-repeat;
  }
  .striped(@color, @angle: -45deg) {
    background-color: @color;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  }
}
// Reset filters for IE
.reset-filter() {
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}



// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
.nav-divider() {
  // IE7 needs a set width since we gave a height. Restricting just
  // to IE7 to keep the 1px left/right space in other browsers.
  // It is unclear where IE is getting the extra space that we need
  // to negative-margin away, but so it goes.
  *width: 100%;
  height: 1px;
  margin: ((@baseLineHeight / 2) - 1) 1px; // 8px 1px
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid @white;
}

// Button backgrounds
// ------------------
.buttonBackground(@startColor, @endColor) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  .gradientBar(@startColor, @endColor);
  *background-color: @endColor; /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  .reset-filter();

  // in these cases the gradient won't cover the background, so we override
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    background-color: @endColor;
    *background-color: darken(@endColor, 5%);
  }

  // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken(@endColor, 10%) e("\9");
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbarVerticalAlign(30px);` to calculate the appropriate top margin.
.navbarVerticalAlign(@elementHeight) {
  margin-top: (@navbarHeight - @elementHeight) / 2;
}

// Popover arrows
// -------------------------
// For tipsies and popovers
#popoverArrow {
  .top(@arrowWidth: 5px, @color: @black) {
    bottom: 0;
    left: 50%;
    margin-left: -@arrowWidth;
    border-left: @arrowWidth solid transparent;
    border-right: @arrowWidth solid transparent;
    border-top: @arrowWidth solid @color;
  }
  .left(@arrowWidth: 5px, @color: @black) {
    top: 50%;
    right: 0;
    margin-top: -@arrowWidth;
    border-top: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid transparent;
    border-left: @arrowWidth solid @color;
  }
  .bottom(@arrowWidth: 5px, @color: @black) {
    top: 0;
    left: 50%;
    margin-left: -@arrowWidth;
    border-left: @arrowWidth solid transparent;
    border-right: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid @color;
  }
  .right(@arrowWidth: 5px, @color: @black) {
    top: 50%;
    left: 0;
    margin-top: -@arrowWidth;
    border-top: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid transparent;
    border-right: @arrowWidth solid @color;
  }
}

// Grid System
// -----------

// Centered container element
.container-fixed() {
  margin-right: auto;
  margin-left: auto;
  .clearfix();
}

// Table columns
.tableColumns(@columnSpan: 1) {
  float: none; // undo default grid column styles
  width: ((@gridColumnWidth) * @columnSpan) + (@gridGutterWidth * (@columnSpan - 1)) - 16; // 16 is total padding on left and right of table cells
  margin-left: 0; // undo default grid column styles
}

// Make a Grid
// Use .makeRow and .makeColumn to assign semantic layouts grid system behavior
.makeRow() {
  margin-left: @gridGutterWidth * -1;
  .clearfix();
}
.makeColumn(@columns: 1, @offset: 0) {
  float: left;
  margin-left: (@gridColumnWidth * @offset) + (@gridGutterWidth * (@offset - 1)) + (@gridGutterWidth * 2);
  width: (@gridColumnWidth * @columns) + (@gridGutterWidth * (@columns - 1));
}

// The Grid
#grid {

  .core (@gridColumnWidth, @gridGutterWidth) {

    .spanX (@index) when (@index > 0) {
      //(~".span@{index}") { .span(@index); }
      .span@{index} { .span(@index); }
      .spanX(@index - 1);
    }
    .spanX (0) {}

    .offsetX (@index) when (@index > 0) {
      //(~".offset@{index}") { .offset(@index); }
      .offset@{index} { .offset(@index); }
      .offsetX(@index - 1);
    }
    .offsetX (0) {}

    .offset (@columns) {
      margin-left: (@gridColumnWidth * @columns) + (@gridGutterWidth * (@columns + 1));
    }

    .span (@columns) {
      width: (@gridColumnWidth * @columns) + (@gridGutterWidth * (@columns - 1));
    }

    .row {
      margin-left: @gridGutterWidth * -1;
      .clearfix();
    }

    [class*="span"] {
      float: left;
      margin-left: @gridGutterWidth;
    }

    // Set the container width, and override it for fixed navbars in media queries
    .container,
    .navbar-fixed-top .container,
    .navbar-fixed-bottom .container { .span(@gridColumns); }

    // generate .spanX and .offsetX
    .spanX (@gridColumns);
    .offsetX (@gridColumns);

  }

  .fluid (@fluidGridColumnWidth, @fluidGridGutterWidth) {

    .spanX (@index) when (@index > 0) {
      //(~".span@{index}") { .span(@index); }
      .span@{index} { .span(@index); }
      .spanX(@index - 1);
    }
    .spanX (0) {}

    .span (@columns) {
      width: (@fluidGridColumnWidth * @columns) + (@fluidGridGutterWidth * (@columns - 1));
      *width: (@fluidGridColumnWidth * @columns) + (@fluidGridGutterWidth * (@columns - 1)) - (.5 / @gridRowWidth * 100 * 1%);
    }

    .row-fluid {
      width: 100%;
      .clearfix();
      [class*="span"] {
        .input-block-level();
        float: left;
        margin-left: @fluidGridGutterWidth;
        *margin-left: @fluidGridGutterWidth - (.5 / @gridRowWidth * 100 * 1%);
      }
      [class*="span"]:first-child {
        margin-left: 0;
      }

      // generate .spanX
      .spanX (@gridColumns);
    }

  }

  .input(@gridColumnWidth, @gridGutterWidth) {

    .spanX (@index) when (@index > 0) {
      //(~"input.span@{index}, textarea.span@{index}, .uneditable-input.span@{index}") { .span(@index); }
      input.span@{index}, textarea.span@{index}, .uneditable-input.span@{index} { .span(@index); }
      .spanX(@index - 1);
    }
    .spanX (0) {}

    .span(@columns) {
      width: ((@gridColumnWidth) * @columns) + (@gridGutterWidth * (@columns - 1)) - 10;
    }

    input,
    textarea,
    .uneditable-input {
      margin-left: 0; // override margin-left from core grid system
    }

    // generate .spanX
    .spanX (@gridColumns);

  }

}
