/*doc
---
title: Home Buscador
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

.node-type-portada-general .form-buscar {
	margin-bottom:20px;
}