/*
  Incluye:
    - Variables de bootstrap
    - Variables propias
*/


/*
  Variables de bootstrap
*/


// GLOBAL VALUES
// --------------------------------------------------


// Grays
// -------------------------
@black:                 #000;
@grayDarker:            #222;
@grayDark:              lighten(#333, 1.5%);
@gray:                  #555;
@grayLight:             #777;
@grisClaro:             #999;
@grayLighter:           #bbb;
@borderDark:            @grayLighter;
@borderLight:           #ddd;
@borderLighter:         #ebebeb;
@grayHover:             #f5f5f5;
@whiteDarker:           #f5f5f5;
@whiteDark:             #fafafa;
@white:                 #fff;


// Accent colors
// -------------------------
@beige:                 #eee5d5;
@mostaza:               #e7d800;
@blue:                  #049cdb;
@blueDark:              #0064cd;
@green:                 #46a546;
@red:                   #bc0000;
@granate:               #8a0000;
@ocre:                  #bca474;
@yellow:                #ffc40d;
@orange:                #f89406;
@pink:                  #c3325f;
@purple:                #7a43b6;


// Colores visitas libres
// -------------------------
@arquitectura:          #e92e24;
@feminismo:             #7a137d;
@teatro:                #007863;
@escultura:             #07687d;
@indispensables:        #b1c526;
@exposiciones:          #cccce5;
@artista-crisis:        #E6864F;
@cuerpo:                #CFAF9D;
@lo-poetico:            #2E7CA3;


// Sedes
// -------------------------
@sabatini:              #333333;
@retiro:                #299926;
@silos:                 #896535;


// Pedagogias
// -------------------------
@actividades:           #9dab31;
@visitas:               #ec4b01;
@proyectos-pedagogias:  #c8b802;
@publicacionesVideos:   #1d7686;
@educacion:             #005776;
@rosaEducacion:         #e98b92;

//centro estudios
@texto-centro-estudios: #708D85;

// Microsites
@gigapixel: #2bc5d1;
@gigapixel-dark: darken(@gigapixel, 8%);
@repensar-guernica: #f8df9e;
@repensar-guernica-dark: darken(@repensar-guernica, 8%);
@itinerancias: #d1ecf0;
@itinerancias-dark: darken(@itinerancias, 8%);


// Scaffolding
// -------------------------
@bodyBackground:        @white;
@textColor:             @gray;
@textColorDark:         @grayDark;
@textColorInverse:      lighten(#777, 8%);

// Carta
// -------------------------
@rojocarta:             #bf2730;

// Links
// -------------------------
@linkColor:             #9a7f4a;
@linkColorHover:        darken(@linkColor, 15%);

@beigeHover:            darken(@beige, 15%);
@mostazaHover:          darken(@mostaza, 4%);

// Typography
// -------------------------
@sansFontFamily:        "Helvetica Neue", Helvetica, Arial, sans-serif;
@serifFontFamily:       Georgia, "Times New Roman", Times, serif;
@monoFontFamily:        Menlo, Monaco, Consolas, "Courier New", monospace;

@blackScout:            'PFDinTextPro-ExtraBlack', sans-serif;
@blackScoutItalic:      'PFDinTextPro-ExtraBlackItalic', sans-serif;
@boldScout:             'PFDinTextPro-Bold', sans-serif;
@boldScoutItalic:       'PFDinTextPro-BoldItalic', sans-serif;
@regularScout:          'PFDinTextPro-Regular', sans-serif;
@regularScoutItalic:    'PFDinTextPro-Italic', sans-serif;
@lightScout:            'PFDinTextPro-Thin', sans-serif;
@lightScoutItalic:      'PFDinTextPro-ThinItalic', sans-serif;
@thinScout:             'PFDinTextPro-Thin', sans-serif;
@thinScoutItalic:       'PFDinTextPro-ThinItalic', sans-serif;

@baseLineHeight:        1.6;
@baseFontFamily:        @sansFontFamily;
@altFontFamily:         @serifFontFamily;

@headingsFontFamily:    inherit; // empty to use BS default, @baseFontFamily
@headingsFontWeight:    normal;    // instead of browser default, bold
@headingsColor:         @textColorDark; // empty to use BS default, @textColor


// Tables
// -------------------------
@tableBackground:                   transparent; // overall background-color
@tableBackgroundAccent:             #f9f9f9; // for striping
@tableBackgroundHover:              #f5f5f5; // for hover
@tableBorder:                       #ddd; // table and cell border


// Buttons
// -------------------------
@btnBackground:                     @white;
@btnBackgroundHighlight:            darken(@white, 10%);
@btnBorder:                         #ccc;

@btnPrimaryBackground:              @linkColor;
@btnPrimaryBackgroundHighlight:     spin(@btnPrimaryBackground, 15%);

@btnInfoBackground:                 #5bc0de;
@btnInfoBackgroundHighlight:        #2f96b4;

@btnSuccessBackground:              #62c462;
@btnSuccessBackgroundHighlight:     #51a351;

@btnWarningBackground:              lighten(@orange, 15%);
@btnWarningBackgroundHighlight:     @orange;

@btnDangerBackground:               #ee5f5b;
@btnDangerBackgroundHighlight:      #bd362f;

@btnInverseBackground:              @gray;
@btnInverseBackgroundHighlight:     @grayDarker;


// Forms
// -------------------------
@inputBackground:               @white;
@inputBorder:                   @grayLighter;
@inputBorderRadius:             0px;
@inputDisabledBackground:       @grayLighter;
@formActionsBackground:         #f5f5f5;

// Dropdowns
// -------------------------
@dropdownBackground:            @white;
@dropdownBorder:                rgba(0,0,0,.2);
@dropdownLinkColor:             @grayDark;
@dropdownLinkColorHover:        @white;
@dropdownLinkBackgroundHover:   @linkColor;




// COMPONENT VARIABLES
// --------------------------------------------------

// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
@zindexDropdown:          1000;
@zindexPopover:           1010;
@zindexTooltip:           1020;
@zindexFixedNavbar:       1030;
@zindexModalBackdrop:     1040;
@zindexModal:             1050;
@zindexMenuSplash:        1060;
@zindexHamburger:         1070;
@zindexCloseFullScreen:   1080;


// Sprite icons path
// -------------------------
@iconSpritePath:          "../bootstrap/img/glyphicons-halflings.png";
@iconWhiteSpritePath:     "../bootstrap/img/glyphicons-halflings-white.png";


// Input placeholder text color
// -------------------------
@placeholderText:         @grayLight;


// Hr border color
// -------------------------
@hrBorder:                @grayLighter;


// Navbar
// -------------------------
@navbarHeight:                    40px;
@navbarBackground:                @grayDarker;
@navbarBackgroundHighlight:       @grayDark;

@navbarText:                      @grayLight;
@navbarLinkColor:                 @grayLight;
@navbarLinkColorHover:            @white;
@navbarLinkColorActive:           @navbarLinkColorHover;
@navbarLinkBackgroundHover:       transparent;
@navbarLinkBackgroundActive:      @navbarBackground;

@navbarSearchBackground:          lighten(@navbarBackground, 25%);
@navbarSearchBackgroundFocus:     @white;
@navbarSearchBorder:              darken(@navbarSearchBackground, 30%);
@navbarSearchPlaceholderColor:    #ccc;
@navbarBrandColor:                @navbarLinkColor;


// Hero unit
// -------------------------
@heroUnitBackground:              @grayLighter;
@heroUnitHeadingColor:            inherit;
@heroUnitLeadColor:               inherit;


// Form states and alerts
// -------------------------
@warningText:             #c09853;
@warningBackground:       #fcf8e3;
@warningBorder:           darken(spin(@warningBackground, -10), 3%);

@errorText:               #b94a48;
@errorBackground:         #f2dede;
@errorBorder:             darken(spin(@errorBackground, -10), 3%);

@successText:             #468847;
@successBackground:       #dff0d8;
@successBorder:           darken(spin(@successBackground, -10), 5%);

@infoText:                darken(@beige, 55%);
@infoBackground:          @beige;
@infoBorder:              @infoBackground;



// GRID
// --------------------------------------------------

// Default 940px grid
// -------------------------
@gridColumns:             12;
@gridColumnWidth:         60px;
@gridGutterWidth:         20px;
@gridRowWidth:            (@gridColumns * @gridColumnWidth) + (@gridGutterWidth * (@gridColumns - 1));

// Fluid grid
// -------------------------
@fluidGridColumnWidth:    6.382978723%;
@fluidGridGutterWidth:    2.127659574%;


/*
  Variables propias
*/

// Breakpoints

@mobile-down: 767px;
@tablet: 768px;
@tablet-down: 979px;
@desktop: 980px;
@desktop-down: 1280px;
@desktop-large: 1281px;

// Grid

@gridGutterWidth-desktop-large: 30px;

// Textos

@extralargeFontSize: 20px;
@smallLineHeight: 1.4;
@thinWeight: 300;
@headingsLineHeight: 1.15;

// Imágenes

@img-full-screen-max-height: ~"calc(100vh - 90px)";
@img-small-full-screen-max-height: ~"calc(50vh - 75px)";

// Tamaños de texto

@fontScale8:  60px;
@fontScale7:  44px;
@fontScale6:  34px;
@fontScale5:  30px;
@fontScale4:  23px;
@fontScale3:  21px;
@fontScale2:  20px;
@fontScale1:  18px;
@fontScale0:  15px;
@fontScale-1: 13px;
@fontScale-2: 11px;
@fontScale-3: 10px;

@baseFontSize:        @fontScale0;
@largeFontSize:       @fontScale1;
@smallFontSize:       @fontScale-1;
@xsmallFontSize:      @fontScale-2;

@fontSizeH1:          @fontScale6;
@fontSizeH1-lg:       @fontScale7;

@fontSizeH2:          @fontScale3;
@fontSizeH3:          @fontScale2;
@fontSizeH4:          @fontScale1;
@fontSizeH5:          @fontScale2;
@fontSizeH6:          @fontScale0;

@fontSizeLead:        @fontScale3;
@fontSizeLead-md:     @fontScale2;
@fontSizeLead-xs:     @fontScale1;

// Espacio en blanco

@space-unit-xxxs:           5px;
@space-unit-xxs:            10px;
@space-unit-xs:             15px;
@space-unit-xsm:            20px;
@space-unit-sm:             (@space-unit-xs * 2); // 30px;
@space-unit-md:             (@space-unit-xs * 3); // 45px;
@space-unit-lg:             (@space-unit-xs * 4); // 60px;
@space-unit-xl:             (@space-unit-xs * 5); // 75px;
@space-unit-xxl:            90px;                 // 90px;

// Sección

@FontSizeSeccionCabeceraTitulo:     @fontScale4;
@FontSizeSeccionCabeceraTitulo-lg:  @fontScale5;

@FontSizeSeccionCabeceraSubtitulo:     @fontScale3;
@FontSizeSeccionCabeceraSubtitulo-lg:  @fontScale4;

// Componentes

@component-active-color: @linkColor;
@component-active-bg: @component-active-color;

// Componentes

@component-active-color: @linkColor;
@component-active-bg: @component-active-color;

// Miniatura
@miniaturaCajaAltoMinimo: 		340px;

// Etiquetas

@etiquetaColor:             darken(@beige, 48%);
@etiquetaBackgroundColor:   @beige;
@etiquetaFontSize:          14px;

// Redes sociales

@facebook:                  #3B5998;
@twitter:                   #598DCA;
@instagram:                 #d82d7b;
@vimeo:                     #63B4E4;
@youtube:                   #CF3427;
@google-plus:               #D6492F;
@rss:                       #E19126;

// Titulo página

@FontSizeTituloPaginaTitulosTitulo: 		    @fontScale6;
@FontSizeTituloPaginaTitulosTitulo-lg:      @fontScale7;

@FontSizeTituloPaginaTitulosSubtitulo:      @fontScale5;
@FontSizeTituloPaginaTitulosSubtitulo-lg:   40px;

@FontSizeTituloPaginaEntradilla:            @fontScale3;

@FontSizeTituloPaginaTexto:                 @fontScale2;

// Iconos

@size-icon-xs:   20px;
@size-icon-sm:   24px;
@size-icon-md:   28px;

// Miniatura destacada

@FontSizeMiniaturaDestacadaAntetitulo:    @fontScale2;

@FontSizeMiniaturaDestacadaTitulo:        @fontScale4;
@FontSizeMiniaturaDestacadaTitulo-lg:     @fontScale6;

@FontSizeMiniaturaDestacadaSubtitulo:     @fontScale2;
@FontSizeMiniaturaDestacadaSubtitulo-lg:  @fontScale5;

// Masonry

@masonryGutter2Columnas: 5%;
@masonryGutter3Columnas: 2.5%;

// Transitions

@transition-base: .2s;

@button-share-facebook-bg: #3b5998;
@button-share-twitter-bg: #00acee;
@button-share-whatsapp-bg: #6FE778;

// Splash

@splash-button-family: 'Arial';
@splash-button-font-size: 24px;