/*doc
---
title: Home Hoy en el Museo
category: Modulos
---

Estilos del bloque "Hoy en el Museo" que aparece en la portada general.

*/

#bloque-con-hoy-museo {

  ul.bloque1 .thumbnail {
    background-color: @white;
  }

	.caja {
		background:@beige;
	}

}

#hoy-en-el-museo {
	margin:0;
	padding:20px 10px;
	border-top:none;
	.titulo-bloque {
		padding-left:0;
		padding-right:0;
		font-size:16px;
		line-height:18px;
		border-top:none;
	}
	.calendario {
		margin-bottom:0;
		margin-left:5px;
		margin-right:5px;
	}
	.ui-datepicker {
		tbody {
			background: #DFD7C7;
			border-left:1px solid #DFD7C7;
			border-top:1px solid #DFD7C7;
		}
		td {
			span,
			a,
			.ui-state-default {
				border-color:#dfd7c7;
			}
		}
		.ui-state-default {
			background: #fff;
			&:hover {
				background: darken(#fff, 5%);
			}
		}
		.ui-state-highlight {
			background: @mostaza;
			&:hover {
				background: @mostaza;
			}
		}
		.ui-state-active {
			background: @grayDark;
			&:hover {
				background: @grayDark;
			}
		}
	}
}
