.antetitle-style(@fontFamily: @lightScout) {
    font-family: @fontFamily;
    font-size: 16px;
    line-height: @headingsLineHeight;
    color: @gray;
}

.title-style(@fontFamily: @boldScout) {
    font-family: @fontFamily;
    font-weight: normal;
    line-height: @headingsLineHeight;
    text-transform: none;
    color: @grayDark;
}

.title-alt-style {
    font-family: @baseFontFamily;
    font-weight: bold;
    line-height: @smallLineHeight;
}

.subtitle-style(@fontFamily: @boldScout) {
    font-family: @lightScout;
    font-weight: normal;
    line-height: @headingsLineHeight;
    text-transform: none;
}

.subtitle-alt-style {
    font-family: @baseFontFamily;
    font-weight: normal;
    line-height: @smallLineHeight;
}
