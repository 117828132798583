.ancho-completo-xs {
	@media (max-width: @mobile-down) {
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.ancho-completo-without-padding-xs {
  @media (max-width: @mobile-down) {
    margin-left: -20px;
    margin-right: -20px;
  }
}
