/*doc
---
title: Flechas
category: Mixins
---

*/

.flecha-derecha (@arrow-color, @arrow-size){
	position: relative;
	&:before {
		position: absolute;  
		content: "";
		border-style: solid;
		z-index: 1;
		border-color: transparent transparent transparent @arrow-color;
		border-width: @arrow-size 0 @arrow-size @arrow-size;
		margin-top: -@arrow-size;
		top: 50%;
		right: -@arrow-size;
		bottom: auto;
		left: auto;
	}
}

.borrar-flecha {
	&:before {
		content: none;
	}
}