/*doc
---
title: Espacios en blanco
category: Mixins
---

Son una serie muy extensa de mixins para aplicar las propiedades de margin y padding en función de una escala de valores definida previamente. La escala esta disponible en el archivo `less/variables.less`.

```
@space-unit-xs: 			15px;
@space-unit-sm:  			(@space-unit-xs * 2); // 30px;
@space-unit-md:  			(@space-unit-xs * 3); // 45px;
@space-unit-lg:  			(@space-unit-xs * 4); // 60px;
@space-unit-xl:  			(@space-unit-xs * 5); // 75px;
```

Se pueden modificar estos valores pero no es recomendable ya que la rejilla de Bootstrap se compone de calles cuya anchura es de 30px, una medida proporcional a nuestra unidad base de 15px.

Los mixins se definen añadiendo a cada propiedad (`margin`, `margin-top`, `padding-bottom`, etc) el sufijo que especifica su tamaño (son cinco: `-xs` , `-sm` , `-md` , `-lg` , `-xl` ).

```
.margin-xs {
	margin: @space-unit-xs;
}

.margin-top-xs {
	margin-top: @space-unit-xs;
}

.margin-top-sm {
	margin-top: @space-unit-sm;
}

.margin-top-md {
	margin-top: @space-unit-md;
}

.margin-top-lg {
	margin-top: @space-unit-lg;
}

.margin-top-xl {
	margin-top: @space-unit-xl;
}

...
```

Disponemos también de otros mixins que sirven para elminar los espacios en blanco del elemento.

```
.no-margin-top {
	margin-top: 0;
}
...
```

Además, tenemos otros mixins que combinan dos propiedades en función de si el espacio que crean es vertical u horizontal.

```
.margin-vertical-xs {
	.margin-top-xs ();
	.margin-bottom-xs ();
}

.margin-vertical-sm {
	.margin-top-sm ();
	.margin-bottom-sm ();
}

.margin-vertical-md {
	.margin-top-md ();
	.margin-bottom-md ();
}

.margin-vertical-lg {
	.margin-top-lg ();
	.margin-bottom-lg ();
}

.margin-vertical-xl {
	.margin-top-xl ();
	.margin-bottom-xl ();
}

.no-margin-vertical {
	margin-top: 0;
	margin-bottom: 0;
}
...
```


*/

.margin-xs {
	margin: @space-unit-xs;
}

.margin-sm {
	margin: @space-unit-sm;
}

.margin-md {
	margin: @space-unit-md;
}

.margin-lg {
	margin: @space-unit-lg;
}

.margin-xl {
	margin: @space-unit-xl;
}

.margin-top-xs {
	margin-top: @space-unit-xs;
}

.margin-top-sm {
	margin-top: @space-unit-sm;
}

.margin-top-sm\@only-tablet {
	@media (min-width: 768px) and (max-width: 979px) {
		margin-top: @space-unit-sm;
	}
}

.margin-top-md {
	margin-top: @space-unit-md;
}

.margin-top-md\@only-tablet {
	@media (min-width: 768px) and (max-width: 979px) {
		margin-top: @space-unit-md;
	}
}

.margin-top-lg {
	margin-top: @space-unit-lg;
}

.margin-top-xl {
	margin-top: @space-unit-xl;
}

.no-margin-top {
	margin-top: 0;
}

.margin-right-xxs {
	margin-right: @space-unit-xxs;
}

.margin-right-xs {
	margin-right: @space-unit-xs;
}

.margin-right-sm {
	margin-right: @space-unit-sm;
}

.margin-right-md {
	margin-right: @space-unit-md;
}

.margin-right-lg {
	margin-right: @space-unit-lg;
}

.margin-right-xl {
	margin-right: @space-unit-xl;
}

.no-margin-right {
	margin-right: 0;
}

.margin-bottom-xxxs() {
	margin-bottom: @space-unit-xxxs;
}

.margin-bottom-xxs {
  margin-bottom: @space-unit-xxs;
}

.margin-bottom-xs() {
	margin-bottom: @space-unit-xs;
}

.margin-bottom-xsm {
	margin-bottom: @space-unit-xsm;
}

.margin-bottom-sm {
	margin-bottom: @space-unit-sm;
}

.margin-bottom-md {
	margin-bottom: @space-unit-md;
}

.margin-bottom-lg {
	margin-bottom: @space-unit-lg;
}

.margin-bottom-xl {
	margin-bottom: @space-unit-xl;
}

.margin-bottom-xxl {
	margin-bottom: @space-unit-xxl;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-left-xxs {
	margin-left: @space-unit-xxs;
}

.margin-left-xs {
	margin-left: @space-unit-xs;
}

.margin-left-sm {
	margin-left: @space-unit-sm;
}

.margin-left-md {
	margin-left: @space-unit-md;
}

.margin-left-lg {
	margin-left: @space-unit-lg;
}

.margin-left-xl {
	margin-left: @space-unit-xl;
}

.no-margin-left {
	margin-left: 0;
}

.margin-vertical-xs {
	.margin-top-xs ();
	.margin-bottom-xs ();
}

.margin-vertical-sm {
	.margin-top-sm ();
	.margin-bottom-sm ();
}

.margin-vertical-md {
	.margin-top-md ();
	.margin-bottom-md ();
}

.margin-vertical-lg {
	.margin-top-lg ();
	.margin-bottom-lg ();
}

.margin-vertical-xl {
	.margin-top-xl ();
	.margin-bottom-xl ();
}

.no-margin-vertical {
	.no-margin-top ();
	.no-margin-bottom ();
}

.margin-horizontal-xs {
	.margin-left-xs ();
	.margin-right-xs ();
}

.margin-horizontal-sm {
	.margin-left-sm ();
	.margin-right-sm ();
}

.margin-horizontal-md {
	.margin-left-md ();
	.margin-right-md ();
}

.margin-horizontal-lg {
	.margin-left-lg ();
	.margin-right-lg ();
}

.margin-horizontal-xl {
	.margin-left-xl ();
	.margin-right-xl ();
}

.no-margin-horizontal {
	.no-margin-left ();
	.no-margin-right ();
}

.padding-xxs {
	padding: @space-unit-xxs;
}

.padding-xs {
	padding: @space-unit-xs;
}

.padding-sm {
	padding: @space-unit-sm;
}

.padding-md {
	padding: @space-unit-md;
}

.padding-lg {
	padding: @space-unit-lg;
}

.padding-xl {
	padding: @space-unit-xl;
}

.padding-top-xxxs {
	padding-top: @space-unit-xxxs;
}

.padding-top-xxs {
  	padding-top: @space-unit-xxs;
}

.padding-top-xs {
	padding-top: @space-unit-xs;
}

.padding-top-sm {
	padding-top: @space-unit-sm;
}

.padding-top-md {
	padding-top: @space-unit-md;
}

.padding-top-lg {
	padding-top: @space-unit-lg;
}

.padding-top-xl {
	padding-top: @space-unit-xl;
}

.padding-top-xxl {
	padding-top: @space-unit-xxl;
}

.padding-top-xxl\@tablet {
	@media (min-width: @tablet) {
		padding-top: @space-unit-xxl;
	}
}

.no-padding-top {
	padding-top: 0;
}

.padding-right-xs {
	padding-right: @space-unit-xs;
}

.padding-right-sm {
	padding-right: @space-unit-sm;
}

.padding-right-md {
	padding-right: @space-unit-md;
}

.padding-right-lg {
	padding-right: @space-unit-lg;
}

.padding-right-xl {
	padding-right: @space-unit-xl;
}

.no-padding-right {
	padding-right: 0;
}

.padding-bottom-xxxs {
	padding-bottom: @space-unit-xxxs;
}

.padding-bottom-xxs {
	padding-bottom: @space-unit-xxs;
}

.padding-bottom-xs {
	padding-bottom: @space-unit-xs;
}

.padding-bottom-sm {
	padding-bottom: @space-unit-sm;
}

.padding-bottom-md {
	padding-bottom: @space-unit-md;
}

.padding-bottom-lg {
	padding-bottom: @space-unit-lg;
}

.padding-bottom-xl {
	padding-bottom: @space-unit-xl;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.padding-left-xs {
	padding-left: @space-unit-xs;
}

.padding-left-sm {
	padding-left: @space-unit-sm;
}

.padding-left-md {
	padding-left: @space-unit-md;
}

.padding-left-lg {
	padding-left: @space-unit-lg;
}

.padding-left-xl {
	padding-left: @space-unit-xl;
}

.no-padding-left {
	padding-left: 0;
}

.padding-vertical-xxxs {
	.padding-top-xxxs ();
	.padding-bottom-xxxs ();
}

.padding-vertical-xxs {
	.padding-top-xxs ();
	.padding-bottom-xxs ();
}

.padding-vertical-xs {
	.padding-top-xs ();
	.padding-bottom-xs ();
}

.padding-vertical-xs\@tablet {
	@media (min-width: @tablet) {
		.padding-top-xs ();
		.padding-bottom-xs ();
	}
}

.padding-vertical-sm {
	.padding-top-sm ();
	.padding-bottom-sm ();
}

.padding-vertical-md {
	.padding-top-md ();
	.padding-bottom-md ();
}

.padding-vertical-lg {
	.padding-top-lg ();
	.padding-bottom-lg ();
}

.padding-vertical-xl {
	.padding-top-xl ();
	.padding-bottom-xl ();
}

.no-padding-vertical {
	.no-padding-top ();
	.no-padding-bottom ();
}

.padding-horizontal-xs {
	.padding-left-xs ();
	.padding-right-xs ();
}

.padding-horizontal-sm {
	.padding-left-sm ();
	.padding-right-sm ();
}

.padding-horizontal-md {
	.padding-left-md ();
	.padding-right-md ();
}

.padding-horizontal-lg {
	.padding-left-lg ();
	.padding-right-lg ();
}

.padding-horizontal-xl {
	.padding-left-xl ();
	.padding-right-xl ();
}

.no-padding-horizontal {
	.no-padding-left ();
	.no-padding-right ();
}
