.body-font-size() {
	font-size: @baseFontSize;
	@media (min-width: @desktop-large) {
		font-size: @baseFontSize + 1;
	}
}

.font-size-large {
	font-size: @largeFontSize;
}

.font-size-base {
	font-size: @baseFontSize;
}

.font-size-small {
	font-size: @smallFontSize;
}

.font-size-big1 {												// 34px, 60px
	font-size: @fontScale6;								
	@media (min-width: @tablet) {
		font-size: @fontScale8;
  }
}

.font-size-title1() {										// 30px, 34px, 44px
	font-size: @fontScale5;
	@media (min-width: @tablet) {
		font-size: @fontScale6;
	}
	@media (min-width: @desktop) {
		font-size: @fontScale7;
	}
}

.font-size-title2() {										// 23px, 30px
	font-size: @fontScale4;
	@media (min-width: @tablet) {
		font-size: @fontScale5;
	}
}

.font-size-subtitle2() {								// 23px, 30px
	font-size: @fontScale4;
	@media (min-width: @tablet) {
		font-size: @fontScale5;
	}
}

.font-size-title3() {										// 20px, 23px
	font-size: @fontScale2;
	@media (min-width: @tablet) {
		font-size: @fontScale4;
	}
}

.font-size-subtitle3() {								// 20px, 23px
	font-size: @fontScale2;
	@media (min-width: @tablet) {
		font-size: @fontScale4;
	}
}

.font-size-title4() {										// 18px, 20px
	font-size: @fontScale1;
	@media (min-width: @tablet) {
		font-size: @fontScale2;
	}
}

.font-size-subtitle4() {								// 18px, 20px
	font-size: @fontScale1;
	@media (min-width: @tablet) {
		font-size: @fontScale2;
	}
}

.font-size-title5() {										// 15px
	font-size: @fontScale0;
}

.font-size-subtitle5() {								// 15px
	font-size: @fontScale0;
}

.font-size-h1 {
	font-size: @fontSizeH1;
	@media (min-width: @desktop-large) {
		font-size: @fontSizeH1-lg;
	}
}

.font-size-h2 {
	font-size: @fontSizeH2;
}

.font-size-h3 {
	font-size: @fontSizeH3;
}

.font-size-h4 {
	font-size: @fontSizeH4;
}

.font-size-h5 {
	font-size: @fontSizeH5;
}

.font-size-h6 {
	font-size: @fontSizeH6;
}

.font-size-seccion__cabecera__titulo {
	font-size: @FontSizeSeccionCabeceraTitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeSeccionCabeceraTitulo-lg;
	}	
}

.font-size-seccion__cabecera__subtitulo {
	font-size: @FontSizeSeccionCabeceraSubtitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeSeccionCabeceraSubtitulo-lg;
	}
}

.font-size-miniatura-destacada-antetitulo {
	font-size: @FontSizeMiniaturaDestacadaAntetitulo;
}

.font-size-miniatura-destacada-titulo {
	font-size: @FontSizeMiniaturaDestacadaTitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeMiniaturaDestacadaTitulo-lg;
	}	
}

.font-size-miniatura-destacada-subtitulo {
	font-size: @FontSizeMiniaturaDestacadaSubtitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeMiniaturaDestacadaSubtitulo-lg;
	}
}

.font-size-titulo-pagina__titulos__titulo {
	font-size: @FontSizeTituloPaginaTitulosTitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeTituloPaginaTitulosTitulo-lg;
	}
}

.font-size-titulo-pagina__titulos__subtitulo {
	font-size: @FontSizeTituloPaginaTitulosSubtitulo;
	@media (min-width: @desktop-large) {
		font-size: @FontSizeTituloPaginaTitulosSubtitulo-lg;
	}	
}

.font-size-titulo-pagina-entradilla {
	font-size: @FontSizeTituloPaginaEntradilla;	
}

.font-size-titulo-pagina__texto {
	font-size: @FontSizeTituloPaginaTexto;	
}